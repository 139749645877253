@value midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .wrapper {
    display: grid;
    grid-template-columns: 65px 1fr;
    gap: md;
    align-items: center;
    width: 100%;

    @media breakpointMdUp {
      grid-template-columns: 88px 1fr;
    }
  }

  .image {
    border-radius: 2px;
  }

  .sizeName,
  .includingFeesText {
    color: midGrey;
  }
}
